<template>
  <main>
    <div
      class="bg-header"
      :class="{ 'short-bg-header': true, 'top-webview' : checkWebview(), 'padding-bg-regis': isHasCaution}"
    ></div>

    <div
      class="container home-content"
      :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
    >
      <h2 class="gift-code-heading">ギフトコード入力完了</h2>

      <div class="gift-code" v-if="giftCodeDetail">
        <div class="gift-code-wrapper">
          <p class="gift-code-title">
            {{ giftCodeDetail.chibijob_gift_title }}
          </p>

          <p class="gift-code-point errors" v-if="giftCodeDetail.point">
            {{ giftCodeDetail.point | numberWithCommas(',', '') }} ptを獲得しました。
          </p>

          <img
            :src="giftCodeDetail.chibijob_gift_image"
            alt=""
            :class="{
              'gift-code-image': true,
              'has-text-body': !!giftCodeDetail.chibijob_gift_body
            }"
          >

          <p
            class="gift-code-body"
            v-if="giftCodeDetail.chibijob_gift_body"
            v-html="giftCodeDetail.chibijob_gift_body"
          />
        </div>
      </div>

      <router-link
        :to="{name: 'Profile.Mypage'}"
        class="btn gift-code-btn"
      >
        マイページへ
        <ChevronRightIcon size="24"/>
      </router-link>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ChevronRightIcon } from 'vue-feather-icons'
import GiftCode from '@/mixins/giftCode.mixin'
import Common from '@/mixins/common.mixin'

export default {
  name: 'Index',

  mixins: [Common, GiftCode],

  components: {
    ChevronRightIcon
  },

  data () {
    return {
      detail: null
    }
  },

  computed: {
    ...mapGetters('giftCode', ['giftCodeDetail']),

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  async created () {
    if (this.isLoggedIn) {
      await this.userPoint()
    }

    const giftCodeInputId = this.$route.params.id

    await this.getDetailGiftCode([giftCodeInputId])
  },

  methods: {
    ...mapActions('giftCode', ['getDetailGiftCode'])
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/_giftcode.scss';

.gift-code {
  &-title {
    margin-bottom: 30px;
  }

  &-point {
    margin-bottom: 50px;
  }

  &-image {
    margin-bottom: 40px;
  }

  &-body {
    margin-bottom: 50px;
  }
}
</style>
